<template>
  <!-- begin::FareTender profile tabs -->
  <div v-if="isAuthenticated">
    <div class="manager-title mb-1">
      <div>
        {{ $t("FARES.TENDER.TITLE_EDIT") + ' - "' + SenderName + '"' }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        {{ t.Title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'profile'">
          <FaresTenderEdit
            :sender-id="SenderID"
            :fare-tender-id="FareTenderID"
          />
        </div>

        <div v-else>
          <FaresTenderDiscount
            :sender-id="SenderID"
            :fare-tender-id="FareTenderID"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::FareTender profile tabs -->
</template>

<script>
import { mapGetters } from "vuex";

import FaresTenderDiscount from "@/view/pages/manager/fares/tender/Discount.vue";
import FaresTenderEdit from "@/view/pages/manager/fares/tender/Edit.vue";

export default {
  name: "FaresTenderTabs",
  components: {
    FaresTenderDiscount,
    FaresTenderEdit
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      SenderID: 0,
      SenderName: "",
      FareTenderID: 0,
      TabList: [
        {
          Position: 0,
          Name: "profile",
          Title: this.$i18n.t("FARES.COMMON.FARE")
        },
        {
          Position: 1,
          Name: "discounts",
          Title: this.$i18n.t("FARES.COMMON.DISCOUNTS")
        }
      ]
    };
  },

  mounted() {
    this.SenderID = parseInt(this.$route.params.senderid);
    this.FareTenderID = parseInt(this.$route.params.fareid);
    if (!this.SenderID || !this.FareTenderID) {
      this.$router.push("/manager/fares?ActiveTab=tender");
    } else {
      this.SenderName = window.sessionStorage.getItem(
        "FareTenderRouteSenderName",
        ""
      );
    }
  }
};
</script>
