<template>
  <!-- begin::FaresTender edit -->
  <div v-if="TenderRouteData">
    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("FARES.COMMON.SUBTITLE_DATA") }}
        </div>

        <!-- begin::TenderRoute data -->
        <div>
          <div class="row">
            <div class="form-group col-lg-4">
              <label>
                {{ $t("TENDER_ROUTES.ORIGIN") }}
              </label>
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginProvince
                    ? TenderRouteData.OriginProvince.Name
                    : ''
                "
                readonly
              />
            </div>

            <div class="form-group col-lg-2">
              &nbsp;
            </div>

            <div class="form-group col-lg-4">
              <label>
                {{ $t("TENDER_ROUTES.DESTINATION") }}
              </label>
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationProvince
                    ? TenderRouteData.DestinationProvince.Name
                    : ''
                "
                readonly
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 form-group">
              <label>
                {{ $t("TENDER_ROUTES.ORIGIN_ADDRESS") }}
              </label>
              <div class="row mb-3">
                <div class="col-lg-12">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.OriginAddress
                        ? TenderRouteData.OriginAddress.TradeName
                        : $t('TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE')
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                    readonly
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.OriginAddress
                        ? TenderRouteData.OriginAddress.Street
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                    readonly
                  />
                </div>
                <div class="col-lg-4">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.OriginAddress
                        ? TenderRouteData.OriginAddress.PostalCode
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.OriginAddress
                        ? TenderRouteData.OriginAddress.City
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                    readonly
                  />
                </div>
                <div class="col-lg-6">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.OriginAddress
                        ? TenderRouteData.OriginProvince.Name
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                    readonly
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6 form-group">
              <label>
                {{ $t("TENDER_ROUTES.DESTINATION_ADDRESS") }}
              </label>
              <div class="row mb-3">
                <div class="col-lg-12">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.DestinationAddress
                        ? TenderRouteData.DestinationAddress.TradeName
                        : $t('TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE')
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                    readonly
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.DestinationAddress
                        ? TenderRouteData.DestinationAddress.Street
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                    readonly
                  />
                </div>
                <div class="col-lg-4">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.DestinationAddress
                        ? TenderRouteData.DestinationAddress.PostalCode
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.DestinationAddress
                        ? TenderRouteData.DestinationAddress.City
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                    readonly
                  />
                </div>
                <div class="col-lg-6">
                  <v-text-field
                    class="form-control"
                    :value="
                      TenderRouteData.DestinationAddress
                        ? TenderRouteData.DestinationProvince.Name
                        : ''
                    "
                    :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::TenderRoute data -->

        <!-- begin:Fare data -->
        <!-- begin::Basic -->
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FARES.COMMON.ESTIMATED_DAYS") }}
            </label>
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.EstimatedDays"
              :placeholder="$t('FARES.COMMON.ESTIMATED_DAYS')"
              type="number"
              min="0"
              step="1"
              :rules="[rules.requiredMin0]"
            />
          </div>
        </div>
        <!-- end::Basic -->

        <!-- begin::PricePerTruck -->
        <div class="row">
          <div class="form-group col-lg-12 mb-0">
            <label>
              {{ $t("FARES.COMMON.PRICE_PER_TRUCK") }}
            </label>
          </div>
          <div class="form-group col-lg-4">
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.PricePerTruck"
              :placeholder="$t('FARES.COMMON.PRICE_PER_TRUCK')"
              type="number"
              min="0"
              step="0.01"
              :rules="[rules.requiredMin0]"
            />
          </div>
          <div class="col-lg-8 d-flex align-items-top">
            <inline-svg src="/media/icons/help-alt-5-primary.svg" />
            <span class="pl-3" v-html="$t('FARES.COMMON.PRICE_PER_TRUCK_INFO')">
            </span>
          </div>
        </div>
        <!-- end::PricePerTruck -->

        <!-- begin::PricePerUnit -->
        <div class="row">
          <div class="form-group col-lg-12 mb-0">
            <label>
              {{ $t("FARES.COMMON.PRICE_PER_UNIT") }}
            </label>
          </div>
          <div class="form-group col-lg-4">
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.PricePerUnit"
              :placeholder="$t('FARES.COMMON.PRICE_PER_UNIT')"
              type="number"
              min="0"
              step="0.01"
              :rules="[rules.requiredMin0]"
            />
          </div>
          <div class="col-lg-8 d-flex align-items-top">
            <inline-svg src="/media/icons/help-alt-5-primary.svg" />
            <span
              class="pl-3"
              v-html="
                $t('FARES.COMMON.PRICE_PER_UNIT_INFO', {
                  typeBasePosition: VehicleTypeBase.Position
                })
              "
            />
          </div>
        </div>
        <!-- end::PricePerUnit -->

        <!-- begin::PriceIncreasePerVehicle -->
        <div class="row mt-3">
          <div class="form-group col-lg-12 mb-0">
            <label>
              {{ $t("FARES.COMMON.PRICE_PER_VEHICLE") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <div class="col-lg-12 d-flex align-items-top">
              <inline-svg src="/media/icons/help-alt-5-primary.svg" />
              <span class="pl-3">
                {{ $t("FARES.COMMON.PRICE_PER_VEHICLE_INFO") }}
              </span>
            </div>
          </div>

          <div class="col-lg-12">
            <v-simple-table
              class="white-table border-box shadow-box pt-0 mt-3 mb-6 coefficentsTable"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                    <td class="text-weight-bold">
                      <strong>{{ $t("FARES.COMMON.SURCHARGE") }}</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="vt in VehicleTypeList"
                    :key="'PriceIncreasePerVehicle_' + vt.Position"
                  >
                    <td>
                      <strong>
                        {{ $t("FLEET.CATEGORIES.TYPE") }}
                        {{
                          vt.Position > VehicleTypeBase.Position
                            ? vt.Position
                            : "1 - " + vt.Position
                        }}:
                      </strong>
                      &nbsp;
                      {{
                        vt.Position > VehicleTypeBase.Position
                          ? vt.Name
                          : VehicleTypeBase.ExtendedName
                      }}
                      <br />
                      <small>
                        {{
                          vt.Position > VehicleTypeBase.Position
                            ? vt.Description
                            : VehicleTypeBase.ExtendedDescription
                        }}
                      </small>
                      <br />
                      <small>
                        {{
                          $t("VEHICLE_TYPES.SIZE") +
                            ": " +
                            $t("VEHICLE_TYPES.LENGTH") +
                            " " +
                            vt.LengthMin +
                            " - " +
                            vt.LengthMax +
                            "; " +
                            $t("VEHICLE_TYPES.WIDTH") +
                            " " +
                            vt.WidthMin +
                            " - " +
                            vt.WidthMax +
                            "; " +
                            $t("VEHICLE_TYPES.HEIGHT") +
                            " " +
                            vt.HeightMin +
                            " - " +
                            vt.HeightMax
                        }}
                      </small>
                    </td>
                    <td>
                      <v-text-field
                        class="form-control shortInput"
                        v-model="Fields.PriceIncreasePerVehicle[vt.Position]"
                        type="number"
                        min="0"
                        max="99999.99"
                        step="0.01"
                        :rules="[rules.min0, rules.maxAlmost100000]"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <!-- end::PriceIncreasePerVehicle -->
        <!-- end:Fare data -->
      </v-form>

      <!-- begin::ActionButtons -->
      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="goBackToSearch"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
      <!-- end::ActionButtons -->
    </div>
  </div>
  <!-- end::FaresTender edit -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import FareTenderRouteService from "@/core/services/api/v2/faretenderroute.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "FaresTenderEdit",
  props: {
    SenderId: {
      type: Number,
      required: true,
      default: -1
    },
    FareTenderId: {
      type: Number,
      required: true,
      default: -1
    }
  },
  data() {
    return {
      TenderRouteData: null,
      Valid: true,
      Fields: {
        EstimatedDays: 0,
        PricePerTruck: 0,
        PricePerUnit: 0,
        PriceIncreasePerVehicle: {}
      },
      VehicleTypeList: [],
      VehicleTypeBase: {
        Position: 0,
        Name: "",
        ExtendedName: "",
        Description: "",
        ExtendedDescription: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        },
        min0: value =>
          parseFloat(value) >= 0 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 }),
        maxAlmost100000: value =>
          parseInt(value) < 100000 ||
          this.$i18n.t("VALIDATION.MAX_VALUE", { max: 99999.99 })
      }
    };
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadVehicleTypeListBaseOrBigger();
  },

  methods: {
    loadVehicleTypeListBaseOrBigger() {
      // As of 2022-03, all vehicles smaller than the "base" VehicleType
      // use the same base PriceIncreasePerVehicle - so ignore them.
      this.VehicleTypeList = [];

      VehicleTypeService.listAll().then(typesList => {
        let smallestName = typesList[0].Name + " - ";
        let smallestDescription = typesList[0].Description + " - ";

        for (let t = 0; t < typesList.length; t++) {
          let maybeBase = typesList[t];
          if (maybeBase.IsBase) {
            this.VehicleTypeBase = {
              Position: maybeBase.Position,
              Name: maybeBase.Name,
              ExtendedName: smallestName + maybeBase.Name,
              Description: maybeBase.Description,
              ExtendedDescription: smallestDescription + maybeBase.Description
            };

            break;
          }
        }

        typesList.forEach(vt => {
          if (vt.Position >= this.VehicleTypeBase.Position) {
            this.VehicleTypeList.push(vt);
          }
        });

        this.loadFareData();
      });
    },

    loadFareData() {
      FareTenderRouteService.getProfile(this.SenderId, this.FareTenderId).then(
        response => {
          if (response === 404) {
            this.goBackToSearch();
          } else {
            this.TenderRouteData = response.TenderRoute;
            for (var f1 in this.Fields) {
              this.Fields[f1] = response[f1];
            }

            this.padPriceIncreasePerVehicle();

            // Remove page loader
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        }
      );
    },

    padPriceIncreasePerVehicle() {
      this.VehicleTypeList.forEach(vt => {
        if (!this.Fields.PriceIncreasePerVehicle[vt["Position"]]) {
          this.Fields.PriceIncreasePerVehicle[vt["Position"]] = 0;
        }
      });
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        FareTenderRouteService.updateProfile(
          this.SenderId,
          this.FareTenderId,
          this.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });
          }

          // Reset submit button
          this.Valid = true;
        });
      }
    },

    goBackToSearch() {
      this.$router.push("/manager/fares/tender/fromsender/" + this.SenderId);
    }
  }
};
</script>
