<template>
  <!-- begin::FareTender discounts -->
  <div
    class="white-box shadow-box p-12"
    title="Pendiente de definir"
    style="height: 50vh"
  >
    TBD...
  </div>
  <!-- end::begin::FareTender discounts -->
</template>

<script>
export default {
  name: "FareTenderDiscount",
  props: {
    SenderId: {
      type: Number,
      required: true,
      default: -1
    },
    FareTenderId: {
      type: Number,
      required: true,
      default: -1
    }
  }
};
</script>
